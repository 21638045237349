import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import Markdown from '../components/markdown'
import Logo from '../components/logo'

const Arrow = ({ fill }) => (
  <span className="arrow">
    <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.552 8.604L10.872 4.302L6.552 0L5.922 0.594L9.27 3.906C8.424 3.852 6.822 3.852 6.372 3.852H0V4.752H6.372C6.822 4.752 8.424 4.752 9.27 4.698L5.922 8.01L6.552 8.604Z" fill={fill || "black"} />
    </svg>
  </span>
)

const Lines = () => (
  <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M19 1H0V0H19V1ZM19 7H0V6H19V7ZM0 13H19V12H0V13Z" fill="black"/>
  </svg>
)

const Cross = () => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.50026 7.20712L12.157 12.8639L12.8642 12.1568L7.20737 6.50001L12.8642 0.843152L12.1571 0.136045L6.50026 5.79291L0.843337 0.135986L0.13623 0.843093L5.79315 6.50001L0.136306 12.1569L0.843413 12.864L6.50026 7.20712Z" fill="black"/>
  </svg>
)

const MenuLink = ({ to, className, fill, children }) => (
  <Link
    className={`link ${className || ''}`}
    to={to}
    getProps={({ isPartiallyCurrent }) =>
      isPartiallyCurrent ? { className: "link active" } : null
    }
  >
    <Arrow fill={fill} />
    <span className="label">
      {children}
    </span>
  </Link>
)

function useHeaderContent() {

  const data = useStaticQuery(graphql`
    {
      allContentfulHomepage {
        nodes {
          address: contactDetails {
            childMarkdownRemark {
              html
            }
          }
          email
          telephone
        }
      }
    }
  `);
  const content = data.allContentfulHomepage.nodes[0];

  return content
}

export const MobileMenu = (props) => {

  const content = useHeaderContent();

  return (
    <div className="mobile-menu" style={{
      color: props.foregroundColour || '#000000'
    }}>
      <MenuLink to="/portfolio" className={`link ${props.isHome ? 'active' : ''}`} fill={props.foregroundColour}>Portfolio</MenuLink>
      <MenuLink to="/silkscreen" fill={props.foregroundColour}>Silkscreen</MenuLink>
      <MenuLink to="/digital" fill={props.foregroundColour}>Digital</MenuLink>
      <MenuLink to="/clients" fill={props.foregroundColour}>Clients</MenuLink>

      <Markdown className="outerx4" html={content.address.childMarkdownRemark.html} />

      <div className="outerx4">
        {content.email} <br />
        {content.telephone}<br />
        <a href="https://instagram.com/makereadymakeready" style={{ opacity: 1 }}>Instagram</a>
      </div>
    </div>
  )
}

// ----------------------------
// NAVIGATION COMPONENT

const Nav = (props) => {

  const content = useHeaderContent();

  const foregroundColour = props.fgColor && props.isFooter ? props.fgColor : '#000000';

  const handleMenuTouch = () => {
    if (props.setMenuOpen) {
      props.setMenuOpen(o => !o)
    }
  }

  return (
    <nav className="max">

      <div className="mobile-menu-btn" onTouchEnd={handleMenuTouch}>
        {props.menuOpen ? <Cross /> : <Lines />}
      </div>

      <div className={`brand ${props.projectDetails || props.isFooter ? 'has-details' : ''}`}>
        <Link to="/" className="logo active">
          <Logo fill={foregroundColour} />
        </Link>

        {props.masthead && !props.isFooter && (
          <div className="markdown masthead">
            {props.masthead.childMarkdownRemark ? (
              // TODO: Tidy this up...
              <Markdown html={props.masthead.childMarkdownRemark.html} />
            ) : (
              <h1>{props.masthead}</h1>
            )}
          </div>
        )}

        {props.projectDetails && !props.isFooter && (
          <div className="nav-project-details">
            <Markdown html={props.projectDetails.childMarkdownRemark.html} />
          </div>
        )}
      </div>

      <div className="menu">
        <div>
          <MenuLink to="/portfolio" className={`link ${props.isHome ? 'active' : ''}`} fill={foregroundColour}>Portfolio</MenuLink>
          <MenuLink to="/silkscreen" fill={foregroundColour}>Silkscreen</MenuLink>
          <MenuLink to="/digital" fill={foregroundColour}>Digital</MenuLink>
          <MenuLink to="/clients" fill={foregroundColour}>Clients</MenuLink>
        </div>

        <Markdown html={content.address.childMarkdownRemark.html} />

        <div>
          {content.email} <br />
          {content.telephone}<br />
          <a href="https://instagram.com/makereadymakeready" style={{ opacity: 1 }}>Instagram</a>
        </div>
      </div>

    </nav>
  )
}

export default Nav
